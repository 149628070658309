.array-container {
    position: absolute;
    width: 100vw;
    /* top: 100px; */
}

.array-bar {
    width: 2px;
    background-color:blueviolet;
    display: inline-block;
    margin: 0 1px;
}